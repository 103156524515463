<template>
  <div class="auth-wrapper">
    <section class="auth-card">
      <div class="col-1">
        <template v-if="!isEmailSent">
          <img
            class="img-1"
            src="@/assets/images/resetPassword/lock.png"
            alt=""
            width="32%"
          />
          <h2 style="margin-bottom: 10px">Reset Password</h2>
          <h5 style="text-align: center">
            Enter your registered minigame email address to receive password reset
            instruction .
          </h5>
          <form action="" @submit.prevent="sendEmail">
            <Input
              label="Email"
              v-model="enteredEmail"
              :validation="['required', 'emailRules']"
              @isValid="enteredEmailIsValid"
              :checkValidationFlag="checkValidationFlag"
              type="email"
              icon="bx bx-envelope bx-tada"
            ></Input>
            <button type="submit" class="auth-btn login-btn lt-hover">
              <Loading v-if="loading"></Loading>
              <div style="display: flex; align-items: center" v-else>
                Send
                <i class="bx bxs-send bx-burst"></i>
              </div>
            </button>
          </form>
          <div class="other-auth">
            <p>Already have account and password?</p>
            <routerLink :to="{ name: 'login' }"> Sign In </routerLink>
          </div>
        </template>
        <template v-else>
          <img src="@/assets/images/resetPassword/email-icon6.png" width="160px" />
          <h3 style="color: #3ce325; margin-bottom: 10px">Check your email</h3>
          <p style="margin: 10px 0 20px 0">
            We have sent a password recovery instruction to your email.
          </p>
          <div class="other-auth">
            <routerLink :to="{ name: 'login' }"> Back to login </routerLink>
          </div>
        </template>
      </div>
      <div class="col-2">
        <img
          class="img-2"
          src="@/assets/images/resetPassword/lock-page-character.png"
          alt=""
          width="130%"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: false,
      enteredEmail: "",
      enteredEmailValidation: null,
      isEmailSent: false,
      checkValidationFlag: false,
    };
  },
  computed: {
    ...mapGetters(["rules"]),
  },

  methods: {
    ...mapActions(["resetPasswordEmailSend"]),
    enteredEmailIsValid(v) {
      this.enteredEmailValidation = v;
    },
    sendEmail() {
      if (this.enteredEmailValidation) {
        this.loading = true;
        this.resetPasswordEmailSend({ email: this.enteredEmail })
          .then((result) => {
            
            this.isEmailSent = true;
          })
          .catch((err) => {
            
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.checkValidationFlag = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/compStyles/_reg&login.scss";
.auth-wrapper {
  height: 100vh;
  .col-1 {
    padding: 50px 20px !important;
    .img-1 {
      aspect-ratio: 1;
    }
  }
  .col-2 {
    background-position: left;
  }
}
</style>
